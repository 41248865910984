import React from "react";
import {
    Link,
    graphql
} from "gatsby";


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

const LodgingPage = ({ data }) => {

    let westportAccommodations = data.file.childPagesYaml.accommodations.filter(x => {
        if (x.location == "Westport"){ return true; } else { return false; };
    });
    let ftBraggAccommodations = data.file.childPagesYaml.accommodations.filter(x => {
        if (x.location == "Fort Bragg"){ return true; } else { return false; };
    });

    return (
        <Layout page='lodging'>
          <SEO title="Lodging" />

          <PageHeader title={ data.file.childPagesYaml.title }/>

          <Row className='page-content'>
            <Col sm/>
            <Col className='col-md-10'>
              <p>{ data.file.childPagesYaml.description }</p>
            </Col>
            <Col sm/>
          </Row>

          <Row className='page-content'>
            <Col sm/>
            <Col className='col-md-10'>
              <h3>Westport</h3>
              <p>{ data.file.childPagesYaml.westport }</p>
              {westportAccommodations.map((value, index) => {
                  return (
                    <div key={index}>
                        <hr />
                        <h4>{ value.name }</h4>
                        <p>{ value.description }</p>
                        <p>{ value.price } - <a href={ value.website }>Website</a></p>
                      </div>
                  );
              })}
            </Col>
            <Col sm/>
          </Row>

          <PageDivider />

          <Row className='page-content'>
            <Col sm/>
            <Col className='col-md-10'>
              <h3>Fort Bragg</h3>
              <p>{ data.file.childPagesYaml.ftBragg }</p>
              {ftBraggAccommodations.map((value, index) => {
                  return (
                    <div key={index}>
                        <hr />
                        <h4>{ value.name }</h4>
                        <p>{ value.description }</p>
                        <p>{ value.price } - <a href='{ value.website }'>Website</a></p>
                      </div>
                  );
              })}
            </Col>
            <Col sm/>
          </Row>

          <PageFooter />

        </Layout>
    );
};


export const query = graphql`
{
  file(relativePath: {eq: "pages/lodging.yml"}) {
    childPagesYaml{
      title
      description
      westport
      ftBragg
      accommodations {
        name
        description
        price
        website
        location
      }
    }
  }
}
`;

export default LodgingPage;
